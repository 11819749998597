import { Row, Col } from "antd";
import React from "react";
import { formatPrice, toCommas } from "src/utils/CommomFunction";
import { isEmpty } from "lodash";

function TotalOder({ detailOrder }) {
  const calcTempBill = () => {
    if (detailOrder) {
      const total = detailOrder.items.reduce(
        (sum, item) => item?.price * item?.qty + sum,
        0
      );
      return total;
    }
  };
  const renderCustomerReceipts = () => {
    return detailOrder?.payment_method_code !== "cashondelivery"
      ? 0
      : toCommas(Math.round(detailOrder?.grand_total));
  };

  console.log({
    detailOrder,
  });
  return (
    <tr key={detailOrder?.id}>
      <td
        colSpan={4}
        style={{
          padding: "0px",
        }}
      >
        <div style={{ width: "100%" }}>
          {detailOrder && (
            <>
              <Row style={{ marginTop: 10 }}>
                <Col span={12}>
                  <div className="text-left">
                    <b>Tạm tính:</b>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text-right">
                    {detailOrder?.grand_total && formatPrice(calcTempBill())} đ
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <b>Khuyến mãi:</b>
                </Col>
                <Col span={12}>
                  <ul
                    className="text-right"
                    style={{
                      color: "#0A8D87",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    {detailOrder?.voucher_code || detailOrder?.merchant_code ? (
                      <li style={{ marginBottom: -15 }}>
                        {`${detailOrder?.merchant_code ?? ""} ${
                          detailOrder?.voucher_code
                            ? `(${detailOrder?.voucher_code})`
                            : ""
                        }`}
                      </li>
                    ) : detailOrder?.discount?.length === 0 &&
                      detailOrder?.vouchers_applied?.length === 0 ? (
                      ""
                    ) : (
                      <>
                        {detailOrder?.discount?.map((item, index) => (
                          <li key={index}>
                            <p>
                              ({item?.label}) Giảm giá{" "}
                              {formatPrice(item?.amount?.value)} đ
                            </p>
                          </li>
                        ))}
                        {detailOrder?.vouchers_applied.map((item, index) => (
                          <li key={index}>
                            <p>
                              {`${item?.code} ${
                                !isEmpty(item?.note) ? `(${item?.note})` : ""
                              }`}
                            </p>
                          </li>
                        ))}
                      </>
                    )}
                  </ul>
                </Col>
                <Col span={4}>
                  <div className="text-right">
                    {detailOrder?.discount?.length === 0 &&
                    detailOrder?.vouchers_applied?.length === 0 ? (
                      <p
                        style={{
                          color: "#0A8D87",
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        0 đ
                      </p>
                    ) : (
                      <>
                        {detailOrder?.discount.map((item, index) => (
                          <p
                            style={{
                              color: "#0A8D87",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                            key={index}
                          >
                            -{formatPrice(item?.amount?.value)} đ
                          </p>
                        ))}
                        {detailOrder?.vouchers_applied?.map((item, index) => (
                          <p
                            key={index}
                            style={{
                              color: "#0A8D87",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            -{formatPrice(item?.amount)} đ
                          </p>
                        ))}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <b>Phí giao hàng:</b>
                </Col>
                <Col span={12}>
                  <div className="text-right">
                    {formatPrice(detailOrder.shipping_amount)} đ
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <b style={{ fontSize: 25 }}>Tổng cộng:</b>
                </Col>
                <Col span={12}>
                  <b style={{ fontSize: 25 }}>
                    <div className="text-right">
                      {detailOrder?.grand_total &&
                        formatPrice(detailOrder?.grand_total)}{" "}
                      đ
                    </div>
                  </b>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <b style={{ fontSize: 25 }}>Tiền thu của khách: </b>
                </Col>
                <Col span={12}>
                  <b style={{ fontSize: 25 }}>
                    <div className="text-right">
                      {renderCustomerReceipts()} đ
                    </div>
                  </b>
                </Col>
              </Row>
            </>
          )}
        </div>
      </td>
    </tr>
  );
}

export default TotalOder;
