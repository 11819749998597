import { CheckOutlined } from "@ant-design/icons";
import { Badge, Menu, Popover, Row, Col } from "antd";

import moment from "moment";
import React, { useState, useEffect } from "react";
import logo from "../../assets/logo_home.png";
import iconNoti from "../../assets/noti.png";
import blackNoti from "../../assets/black-noti.png";
import { useHistory } from "react-router-dom";
import { getLocalStorage } from "src/utils/CommomFunction";
import { ConfirmLogoutModal } from "../../components/Modal/ModalConfirmLogout";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";

import "./index.scss";
import {
  apiGetNotificationList,
  apiMakeReadNotification,
  apiMakeReadAllNotification,
} from "../../apis/Notification";
import { apiGetListOrderRefund } from "apis/Order";
function Header(props) {
  const { reload } = props;
  const history = useHistory();
  const [isShowConfirmLogout, setIsShowConfirmLogout] = useState(false);
  const [refundOrderList, setRefundOrderList] = useState([]);
  const [listNotifications, setListNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadMore, setLoadMore] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalUnread, setTotalUnread] = useState(0);

  const getRefundOrderList = () => {
    apiGetListOrderRefund().then((res) => {
      if (!res.errors && res.data) {
        setRefundOrderList(
          res.data.merchantOrderHistory?.orders.filter(
            (item) => item.flag_refund === 1
          )
        );
      }
    });
  };
  const getListNotifications = () => {
    apiGetNotificationList({ type: "merchant" }).then((res) => {
      if (res.data && !res.errors) {
        setListNotifications(res.data.notifications.list);
        setTotalPages(res?.data?.notifications?.page_info?.total_pages);
        setTotalUnread(res?.data?.notifications?.total_unread);
      }
    });
  };
  useEffect(() => {
    getListNotifications();
    getRefundOrderList();
  }, [reload]);

  const calTime = (created_date) => {
    return moment(created_date).format("h:mm A, DD/MM/yyyy");
  };

  const readNotification = (id) => {
    apiMakeReadNotification({ id }).then((res) => {
      if (res.data && !res.errors) {
        const indexItem = listNotifications.findIndex((it) => it?.id == id);
        if (indexItem !== -1) {
          const temp = [...listNotifications];
          temp[indexItem].is_read = 1;
          setListNotifications(temp);
        }
      }
    });
  };

  const makeReadAllNotification = () => {
    apiMakeReadAllNotification().then((res) => {
      if (res.data && !res.errors) {
        const temp = [...listNotifications];
        for (let i = 0; i < temp.length; i++) {
          temp[i].is_read = 1;
        }
        setListNotifications(temp);
        setTotalUnread(0);
      } else {
        alert(res?.errors?.[0]?.message);
      }
    });
  };

  const styleNotificationItem = (is_read) => {
    if (is_read === 1) {
      return {
        opacity: 0.8,
        height: "100px",
        padding: "20px",
        display: "flex",
        cursor: "pointer",
        borderBottom: "1px solid #eeeeee",
        alignItems: "center",
      };
    }
    return {
      height: "100px",
      padding: "20px",
      display: "flex",
      cursor: "pointer",
      borderBottom: "1px solid #eeeeee",
      alignItems: "center",
    };
  };

  const iconStyle = (is_read) => {
    if (is_read === 0) {
      return {
        height: "48px",
        width: "48px",
        borderRadius: "300px",
        backgroundColor: "#FFC522",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginRight: 20,
      };
    }
    return {
      height: "48px",
      width: "48px",
      borderRadius: "300px",
      backgroundColor: "#C9C9C9",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      marginRight: 20,
    };
  };

  const getMoreNoties = (page) => {
    apiGetNotificationList({
      type: "merchant",
      currentPage: page,
    }).then((res) => {
      if (res.data && !res.errors) {
        const moreNoties = res?.data?.notifications?.list || [];
        setListNotifications(listNotifications.concat(moreNoties));
        setTotalPages(res?.data?.notifications?.page_info?.total_pages);
        setTotalUnread(res?.data?.notifications?.total_unread);
      }
      setLoadMore(false);
    });
  };

  const loadMore = () => {
    if (currentPage < totalPages && !isLoadMore) {
      setLoadMore(true);
      setCurrentPage(currentPage + 1);
      getMoreNoties(currentPage + 1);
    }
  };

  const noti = () => (
    <InfiniteScroll
      dataLength={listNotifications?.length}
      next={loadMore}
      hasMore={true}
      height={500}
      loader={
        isLoadMore ? (
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <Spin size="small" style={{ color: "pink" }} />
          </div>
        ) : (
          <></>
        )
      }
    >
      {listNotifications.map((item, idx) => (
        <div
          key={idx}
          style={styleNotificationItem(item.is_read)}
          onClick={() => readNotification(item.id)}
        >
          <div style={iconStyle(item.is_read)}>
            <img
              style={{ cursor: "pointer", width: "40px", height: "40px" }}
              src={blackNoti}
              alt=""
            ></img>
          </div>
          <div className="content-right" style={{ width: 330 }}>
            <span
              style={{
                display: "inline-block",
                whiteSpace: "break-spaces",
                overflow: "visible",
              }}
            >
              {item.content}
            </span>
            <p>{calTime(item.created_date)}</p>
          </div>
        </div>
      ))}
    </InfiniteScroll>
  );
  const handleSignOut = () => {
    setIsShowConfirmLogout(true);
  };

  const title = (
    <Row>
      <Col flex="auto">
        <div className="notification-title">THÔNG BÁO</div>
      </Col>
      <Col flex="40px">
        <CheckOutlined
          onClick={makeReadAllNotification}
          style={{ fontSize: 28, color: "#0A8D87", cursor: "pointer" }}
        />
      </Col>
    </Row>
  );

  return (
    <>
      <div className="header">
        <div className="container-box header-inner">
          <div className="header-left">
            <img
              style={{ cursor: "pointer" }}
              className="header-logo"
              src={logo}
              alt={"logo"}
              onClick={() => history.push("/")}
            ></img>
            <div className="header-title">
              <h3>{`${getLocalStorage("restaurant")}`}</h3>
              <h5>{getLocalStorage("address")}</h5>
            </div>
          </div>
          <div className="header-right">
            <Badge
              count={refundOrderList?.length}
              className="refund-noti"
              style={{
                backgroundColor: "#ffffff",
                color: "#000",
                borderColor: "#ffffff",
                fontWeight: "bold",
                top: "5px",
                right: "5px",
              }}
            >
              <button
                className="header-btn"
                onClick={() => history.push("/refunds")}
              >
                HOÀN TIỀN
              </button>
            </Badge>
            <button
              style={{ marginRight: 25 }}
              className="header-btn"
              onClick={() => history.push("/history")}
            >
              LỊCH SỬ ĐƠN HÀNG
            </button>
            <button
              style={{ marginRight: 25 }}
              className="header-btn"
              onClick={() => history.push("/shipper")}
            >
              SHIPPER
            </button>
            <Popover
              title={title}
              content={noti}
              placement="bottomRight"
              className="dropdown-noti"
              trigger="click"
            >
              <Badge
                count={totalUnread ?? 0}
                style={{
                  backgroundColor: "#FFC522",
                  color: "#000",
                  borderColor: "#FFC522",
                  fontWeight: "bold",
                  top: "5px",
                  right: "2px",
                }}
              >
                <img style={{ cursor: "pointer" }} src={iconNoti} alt=""></img>
              </Badge>
            </Popover>
            <button className="button button-primary " onClick={handleSignOut}>
              ĐĂNG XUẤT
            </button>
          </div>
        </div>
      </div>
      <ConfirmLogoutModal
        isShowConfirmLogout={isShowConfirmLogout}
        closeModalConfirmLogout={() => setIsShowConfirmLogout(false)}
      />
    </>
  );
}

export default Header;
